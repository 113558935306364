import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private ofAuth: AngularFireAuth,
    private db: AngularFirestore
  ){}

  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    return  new Promise((resolve, reject)=>{
      return  this.ofAuth.authState
              .subscribe((res)=>{
                if(res != undefined && res != null){
                  return  this.db.doc('master/'+ res.uid)
                            .valueChanges()
                            .subscribe((doc:any) => {
                              // console.log(doc);
                              if(doc != undefined){
                                if(doc.admin || doc.editor){
                                  resolve(true);
                                }else{
                                  reject(false);
                                  this.router.navigate(['/login']);
                                }
                              }else{
                                reject(false);
                                this.router.navigate(['/login']);
                              }
                            })             
                }else{
                  reject(false);
                  this.router.navigate(['/login']);
                }
              })
    })
  }


}
