import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

//my module
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { environment } from '../environments/environment';
import { LoginPageModule } from './pages/login/login.module';
import { PanelDeControlPageModule } from './pages/panel-de-control/panel-de-control.module';
import { MyComponentsModule } from './components/myComponentsModules';


@NgModule({
  //COMPONENTS
  declarations: [
    AppComponent
  ],
  entryComponents: [],
  //MUDULES
  imports: [
    BrowserModule, 
    IonicModule.forRoot(), 
    LoginPageModule,
    PanelDeControlPageModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebaseConfig, 'delagrosInfo'),
    AngularFireAuthModule,
    AngularFirestoreModule,
    MyComponentsModule
  ],
  //SERVICES
  providers: [
    StatusBar,
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
