import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth/auth.service';
import { FuncionesGenericasService } from '../../services/funciones-genericas.service';
import { Router } from '@angular/router';
import { AlertasService } from 'src/app/services/alertas.service';
import { AlertController, LoadingController } from '@ionic/angular';

@Component({
  selector: 'app-login',
  templateUrl: './login.page.html',
  styleUrls: ['./login.page.scss'],
})
export class LoginPage implements OnInit {

  //variables para correo login
  correoLoginValor:string;
  correoLoginLabel = "labelAbajo"; //valores posibles [labelAbajo, labelArriba] default [labelAbajo]
  errorCorreoLogin:string = '';//posibles valores [error, null]

  //variables para contra login
  contraLoginValor:string;
  contraLoginLabel = "labelAbajo"; //valores posibles [labelAbajo, labelArriba] default [labelAbajo]
  errorContraLogin:string = '';//posibles valores [error, null]

  constructor(
    private auth: AuthService,
    private funcionesGenericas: FuncionesGenericasService,
    private router: Router,
    private mensajeAlertas: AlertasService,
    public alertCtrl: AlertController,
    public loader: LoadingController
  ) { } 

  ngOnInit() {
    document.getElementById("correoLogin").focus();
  }

  /*FUNCIONES PARA LOGIN*/

  //funciones para correo login
  clickCorreo(){
    document.getElementById("correoLogin").focus();
  }
  enfoqueCorreo(){
    this.correoLoginLabel = "labelArriba";
  } 
  desenfoqueCorreo(){
    //si tiene texto
    if(this.correoLoginValor == null || this.correoLoginValor.replace(/ /g, "") == ''){
      this.correoLoginLabel = "labelAbajo";
    }
  }

  //funciones para contrasena login
  clickContra(){
    document.getElementById("contraLogin").focus();
  }
  enfoqueContra(){
    this.contraLoginLabel = "labelArriba";
  }
  desenfoqueContra(){
    if(this.contraLoginValor == null || this.contraLoginValor.replace(/ /g, "") == ''){
      this.contraLoginLabel = "labelAbajo";
    }
  }

  async validarDatosLogin(){
    var correo = this.correoLoginValor;
    var contra = this.contraLoginValor;

    //validar correo y pass
    var validarCorreo = await this.funcionesGenericas.validacionCorreo(correo);
    var validarContra = await this.funcionesGenericas.validacionContra(contra);

    if(validarCorreo){
      //todo bien
      this.errorCorreoLogin = '';
    }else{
      //algo mal
      this.errorCorreoLogin = 'error';
    }

    if(validarContra){
      //todo bien
      this.errorContraLogin = '';
    }else{
      //algo mal
      this.errorContraLogin = 'error';
    }

    //envio de datos
    if(validarCorreo && validarContra){
      this.correoLoginValor = this.correoLoginValor.trim();
      this.contraLoginValor = this.contraLoginValor.trim();
      var login = await this.enviarDatos();
      
      //si el envio se realiza sin problemas
      if(login){
        this.errorCorreoLogin = '';
        this.errorContraLogin = '';
        document.getElementById("correoLogin").blur();
        document.getElementById("contraLogin").blur();
        // this.router.navigate(['/panel-de-control/resumen']);
        this.router.navigate(['/panel-de-control/novedades']);
      }else{
        this.errorCorreoLogin = 'error';
        this.errorContraLogin = 'error';

        var encabezado:string = 'Error';
        var mensaje:string = 'Existe error en el correo o la contraseña';
        this.mensajeAlertas.mostrarMensajeSimple(encabezado, mensaje);
      }

    }
  } 

  private enviarDatos(){
    return this.auth.loginUser(this.correoLoginValor, this.contraLoginValor)
          .then((res) => {
            console.dir('bien////n' + res);
            return true;
          })
          .catch((err) => {
            console.dir('mal///n', err);
            return false;
          })
  }

  abrirAlertRestaurarContrasena(){
    this.alertCtrl.create({
      header: "Restaurar contraseña",
      subHeader: 'Ingresa la dirección de correo asociado a tu cuenta.',
      message: 'Te enviaremos un enlace a tu correo para restaurarla.',
      cssClass: 'alertcss',
      inputs: [
        {
          name: 'correo',
          placeholder: 'Correo'
        },
      ],
      buttons: [
        {
          text: 'Cancelar',
          handler: () => {this.cerrarAlertRestaurarContrasena()}
        },
        {
          text: 'Enviar',
          handler: (data) => {
            this.validacionCorreoContrasenaOlvidada(data.correo);
          }
        }
      ]
    }).then((res)=>{
      res.present();
    });
  }

  async cerrarAlertRestaurarContrasena(){
    this.alertCtrl.dismiss(); 
  }

  async validacionCorreoContrasenaOlvidada(correo:string){
    //abrimos loader
    await this.abrirLoaderRecetearContrasena();

    //validar correo
    var validarCorreo = await this.funcionesGenericas.validacionCorreo(correo);

    //si no paso la validacion lanza un mensaje
    if(!validarCorreo){
      this.alertCtrl.create({
        subHeader: "El correo no es valido",
        cssClass: 'alertcss',
        buttons: ['Entendido']
      }).then((res)=>{
        res.present();
      });      
      this.cerrarLoader();
      return false;
    }
    
    //enviamos correo
    correo = correo.trim();
    await this.auth.restaurarContrasena(correo).then(
      ()=>{
        //si llega hasta aqui todo esta bien, lanzamos mensaje de exito
        this.alertCtrl.create({
          subHeader: "Te hemos enviado un mensaje a tu correo.",
          cssClass: 'alertcss',
          buttons: ['Entendido']
        }).then((res)=>{
          res.present();
        });
        this.cerrarLoader();
        this.cerrarAlertRestaurarContrasena();
        return true;
      },
      ()=>{
        this.alertCtrl.create({
          subHeader: "Hubo un problema, intentalo luego",
          cssClass: 'alertcss',
          buttons: ['Entendido']
        }).then((res)=>{
          res.present();
        });
        this.cerrarLoader();
        return false;
    });

  }


  async abrirLoaderRecetearContrasena(){
    return  this.loader.create({
              backdropDismiss: false,
              message: 'Resetiando contraseña'
            }).then((res)=>{
              res.present().then(()=>{
                console.log('loader abierto');
                return true;
              });
            });
  }

  cerrarLoader(){
    //tenemos un timeout para asegurarnos
    //que el loader este en la pantalla antes de cerrarlo
    setTimeout(()=>{
      this.loader.dismiss();
      console.log('cerrando loader');
    },500);
    
    //colocamos otro timeout para prevenir que se quede en pantalla el loader
    //busar la manera de mejorar esto, esto arroja un error si el loader ya esta cerrado
    //no bloquea nada pero arroja error
    setTimeout(()=>{
      this.loader.dismiss().catch(()=>{});
    },2000);
  }


}
