import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FuncionesGenericasService {

  constructor() { }

  //validacion correo
  async validacionCorreo(valor:string){
    var validacion:boolean; 

    //nulos o indefinida
    validacion = await this.esNulo(valor);

    //espacios en blanco
    if(validacion){    
      valor = valor.trim();
      validacion = await this.espaciosEnBlanco(valor);
    }
    if(validacion){
      validacion = await this.correoValido(valor);
    }
    if(validacion){
      return true;
    }
    return false;

  }

  //validacion contrasena
  async validacionContra(valor:string){
    var validacion:boolean; 

    //nulos o indefinida
    validacion = await this.esNulo(valor);

    //espacios en blanco
    if(validacion){ 
      valor = valor.trim();   
      validacion = await this.espaciosEnBlanco(valor);
    }
    if(validacion){
      validacion = await this.contraValido(valor);
    }
    if(validacion){
      return true;
    }
    return false;
  }

  //si no esta vacio da true 
  noEstaVacio(valor:string){
    if(valor != null && valor != undefined && valor != 'undefined' && valor.length > 0){
      return true;
    }else{
      return false;
    }
  }

  //las validaciones seran positivas si todo va bien de lo contrario retornara false
  esNulo(valor:string){
    if(valor != null && valor != undefined && valor != 'undefined'){
      return true;
    }
    return false;
  }

  espaciosEnBlanco(valor:string){
    if(valor.indexOf(' ') == -1){
      return true;
    }
    return false;   
  }

  correoValido(valor:string){
    //expresion regular para evaluar el correo '/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3,4})+$/';
    if(/^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i.test(valor)){
      return true;
    }
    return false;  
  }

  contraValido(valor:string){
    if(valor.length > 3){
      return true;
    }
    return false;
  }

  
}
