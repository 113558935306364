// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  firebaseConfig: {
    apiKey: "AIzaSyC93ifTIkIdh62H8qbAZZQQ-lvKDwHDftc",
    authDomain: "logdelagros.firebaseapp.com",
    databaseURL: "https://logdelagros.firebaseio.com",
    projectId: "logdelagros",
    storageBucket: "",
    messagingSenderId: "136505308726",
    appId: "1:136505308726:web:633f37a69fe273ad",
    token: "AIzaSyC93ifTIkIdh62H8"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
