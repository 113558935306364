import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth/auth.service'

@Component({
  selector: 'app-panel-de-control',
  templateUrl: './panel-de-control.page.html',
  styleUrls: ['./panel-de-control.page.scss'],
})
export class PanelDeControlPage implements OnInit {

  currentUrl: string;
  logUser: any;
  logUserId: string;
  logUserName: string;
  logUserAvatar: string;
  sidebarEstado:string = 'reduce';

  constructor(
    auth: AuthService
  ) { } 

  ngOnInit() {
    // this.auth.userLoginData().subscribe(user => {
    //   this.logUser = user;
    //   this.logUserId = user.uid;
    //   this.getDataUser();
    // });

    document.body.addEventListener('mousedown', this.restaurarInsideMenuState, true);
  }

  restaurarInsideMenuState(e){
    // if(e.target.parentNode.className != 'userContainer mat-ripple'){
    //   this.insideMenuState = 'ocultar';
    // }
  }

  getDataUser(){
    // this.auth.getUserLoginData(this.logUserId).subscribe(user => {
    //   this.logUserName = user['nombre'];
    //   this.logUserAvatar = user['imagen'];
    // })
  }

  logOut(){
    // this.router.navigate(['/login']);
    // this.authService.logOut();
  }

  iraBusqueda(){
    // this.router.navigate(['/inicio/busqueda']);
  }

  buscar(valor){
    // let searchInvisible = document.getElementById('generalSearch2');
    //     searchInvisible.setAttribute("value", valor.target.value);
    //     searchInvisible.dispatchEvent(new Event("input"))
  }

  opcinesSidebar(){
    if(this.sidebarEstado == 'open'){
      this.sidebarEstado = 'reduce';
    }
  }

  abrirCerrarSidebar(){
    if(this.sidebarEstado == 'open'){
      this.sidebarEstado = 'reduce';
    }else{
      this.sidebarEstado = 'open';
    }
  }

}
