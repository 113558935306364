import { Component, OnInit } from '@angular/core';
import { PopoverController, NavParams } from '@ionic/angular';

@Component({
  selector: 'app-pop-over-menus',
  templateUrl: './pop-over-menus.component.html',
  styleUrls: ['./pop-over-menus.component.scss'],
})
export class PopOverMenusComponent implements OnInit {

  tipoMenu:string; //indica cual popOver debe mostrarse posibles opciones [menuUsuario, menuNovedades]

  constructor(
    private popOver: PopoverController,
    private navParams: NavParams
  ) { }

  ngOnInit() {
    //var data = this.navParams.data;
    this.tipoMenu = this.navParams.get('tipoMenu');

    if(this.tipoMenu == 'menuUsuario'){
      this.mostrarMenuUsuario();
    }else if(this.tipoMenu == 'menuNovedades'){
      this.mostrarMenuNovedades();
    }else if(this.tipoMenu == 'menuListaNovedades'){
      this.mostrarMenuListaNovedades();
    }
  }

  mostrarMenuUsuario(){
    document.getElementById('cerrarSesion').className = 'opcion mostrar';
  }

  mostrarMenuNovedades(){
    document.getElementById('eliminarNovedad').className = 'opcion mostrar';
  }

  mostrarMenuListaNovedades(){
    document.getElementById('estatusAantigua').className = 'opcion mostrar';
  }

  opcionSeleccionada(valor:string){
    this.popOver.dismiss({
      opcion: valor
    })
  }

}
