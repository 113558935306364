import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  userData:any;
  userUid:any;

  constructor(
    private ofAuth: AngularFireAuth,
    private db: AngularFirestore
  ) { }

  
  public loginUser(email:string, pass:string){
    return new Promise((resolve, reject) => {
      this.ofAuth.auth.signInWithEmailAndPassword(email, pass)
      .then((userData) => {
        resolve(userData); 
      },
      err => reject (err));
    })
  }

  public getUserUid(){
    return  new Promise((resovel, reject)=>{
      return  this.ofAuth.authState
              .subscribe((res)=>{
                if(res.uid != undefined && res.uid != 'undefined'){
                  resovel(res.uid);
                }else{
                  reject(false);
                }
              })
    })
  }

  public async getUserData(){
    this.userUid = await this.getUserUid();

    return new Promise((resolve, reject)=>{
      this.userData =   this.db.doc('master/'+ this.userUid)
                          .valueChanges()
                          .subscribe(doc => {
                            resolve(doc);
                            this.userData.unsubscribe();
                          });
    });
  }

  public async cerrarSesion(){
    return new Promise((resolve, reject)=>{
      this.ofAuth.auth.signOut().then(()=>{
        resolve();
      });
    });
  }

  restaurarContrasena(email:string){
    return new Promise((resolve, reject)=>{
      return this.ofAuth.auth.sendPasswordResetEmail(email).then(
              (res)=>{
                console.dir('Correo de restauracion enviado')
                resolve(true)
              },
              (err)=>{
                console.dir('Correo de restauracion NO enviado, algo salio mal');
                reject (false)
              }
            );
    });
  }

}
