import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router'
import { PanelDeControlPage } from './pages/panel-de-control/panel-de-control.page';
import { AuthGuard } from 'src/app/guards/auth.guard';

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  // PAGINAS DEL PARA ADMIN
  { path: 'login', loadChildren: './pages/login/login.module#LoginPageModule' },
  { path: 'panel-de-control',
    component: PanelDeControlPage, 
    canActivate: [AuthGuard],
    children: [
      { path: '', redirectTo: 'novedades', pathMatch: 'full' },
      { path: 'resumen', loadChildren: './pages/panel-de-control/inicio/inicio.module#InicioPageModule' },
      { path: 'novedades', loadChildren: './pages/panel-de-control/novedades/novedades.module#NovedadesPageModule' },
      { path: 'novedad/:id', loadChildren: './pages/panel-de-control/novedad/novedad.module#NovedadPageModule' },      
      { path: 'novedadNueva', loadChildren: './pages/panel-de-control/novedadNueva/novedadNueva.module#NovedadNuevaPageModule' }

    ] 
  },
  // PAGINAS PARA USUARIOS
  { path: 'inicio/:id', loadChildren: './pages/inicio/inicio.module#InicioPageModule' },
  { path: 'articulos/:id', loadChildren: './pages/articulos/articulos.module#ArticulosPageModule' },
  //PAGINAS COMUNES
  { path: 'error', loadChildren: './pages/error/error.module#ErrorPageModule' },
  { path: '**', redirectTo: 'error', pathMatch: 'full' },

 
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
